@import "../variables.scss";

.pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  margin-top: 64px;
  text-align: center;

  &__inputs {
    margin: 40px 0;
    position: relative;

    &__ghostInput {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0;
    }

    &__input {
      $size: 44px;
      height: $size;
      width: $size;
      margin: 8px;
      background-color: rgba($white, 0.1);
      color: white;
      border: 0;
      border-radius: 4px;
      text-align: center;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &--active {
        background-color: rgba($white, 0.4);
      }

      @media screen and (max-width: 520px) {
        margin: 4px;
      }
    }
  }
}

#trigger-open-keyboard {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  font-size: 18px;
  opacity: 0;
}

.help {
  margin-top: 96px;
}
