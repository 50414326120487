@import "../variables.scss";

.button {
  @include bodyFont;
  width: 100%;
  appearance: none;
  background-color: $primaryBlue;
  color: $white;
  border: 0;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &--loading:before {
    content: "Loading...";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primaryBlue;
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
}
