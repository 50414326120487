@import "../variables.scss";

.loginForm {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;

  &__input {
    @include bodyFont;
    width: 100%;
    margin: 6px 0 8px 0;
  }

  .button {
    margin-top: 6px;
  }
}

.forgotPassword {
  margin-top: 24px;
}

.signUp {
  margin-top: 8px;
}
