@import "./variables.scss";
@import "./reset.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgDark;
  font: var(--font-demi), system-ui, sans-serif;
  color: $white;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  padding: 0 10px;
}

.title {
  @include titleFont;
  color: $white;

  &--center {
    text-align: center;
  }
}

.text {
  @include bodyFont;
  color: $white;

  &--center {
    text-align: center;
  }

  &--muted {
    color: rgba($white, 0.6);
  }

  a {
    color: $cyan;
    text-decoration: none;
  }

  &.error {
    color: $red;
  }
}

.input {
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba($white, 0.1);
  border: 0;
  color: white;
  padding: 10px;

  &:focus {
    outline: none;
  }

  &--error {
    border: 1px solid #e94138;
  }

  &:-internal-autofill-selected {
    background-color: inherit;
    color: inherit;
  }
}

.input-wrapper {
  .input-error {
    color: rgba(252, 252, 252, 0.6);
    padding: 0 0 10px 0;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }
}
