.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  margin-top: 64px;

  .tick-circle {
    margin-bottom: 34px;
    width: 60px;
    height: 60px;
  }

  .text {
    font-size: 20px;
    line-height: 28px;
  }
}
