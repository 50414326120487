$white: #fcfcfc;
$bgDark: #222742;
$primaryBlue: #2259ff;
$bgBase: #181d38;
$cyan: #68ddff;
$red: red;

@mixin bodyFont {
  font: var(--font-demi);
  font-size: 16px;
  line-height: 24px;
}

@mixin titleFont {
  font: var(--font-medium);
  font-size: 20px;
  line-height: 28px;
}

@mixin headingFont {
  font: var(--font-bold);
  font-size: 36px;
  line-height: 48px;
}
