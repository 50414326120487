@import "../variables.scss";

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($bgBase, 0.6);

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: spin 0.6s linear infinite;
  }
}
