@import "../variables.scss";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  &__title {
    @include headingFont;
  }
}
